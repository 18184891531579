import { dev } from '$app/environment'
import * as Sentry from '@sentry/sveltekit'
import { handleErrorWithSentry, Replay } from '@sentry/sveltekit'

Sentry.init({
  dsn: 'https://fa5cff67aeef78a4de1b865112203ac1@o4504939460689920.ingest.sentry.io/4506475878219776',
  tracesSampleRate: 1.0,
  enabled: dev ? false : true,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  // If you don't want to use Session Replay, just remove the line below:
  integrations: [new Replay()],
})

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry()
